body {
  overflow-x: hidden;
  font-family: 'Open Sans' !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

a {
  text-decoration: none !important;
}

.phonebox a {
  color: #fff;
}

.phonebox a:hover {
  color: #1F589A;
}

.emailbox a {
  color: #fff;
}

.emailbox a:hover {
  color: #1F589A;
}

.mobhil_view,
.mobhilfooter {
  display: none !important;
}

.me-auto .navbar-nav a {
  text-decoration: none;
  color: #1f589a;
}

.hdmainclass {
  position: absolute;
  width: 100%;
}

.navbarmenu {
  overflow: hidden;
}

.navbarmenu .subnav a {
  font-size: 16px;
  color: #1F589A;
  text-align: center;
  text-decoration: none !important;
  font-weight: 600;
}

.nav-link a {
  text-decoration: none;
  color: #1F589A;
}

.navbarmenu {
  float: left;
  overflow: hidden;
  display: flex;
}

.navbarmenu .subnavbtn {
  font-size: 18px;
  border: none;
  outline: none;
  color: #1f589b;
  padding: 14px 16px;
  background-color: inherit;
  margin: 0;
}

.subservicetwo {
  background-color: #1f599b;
  display: flex;
  justify-content: center;
  border: 3px solid #fff;
}

.subservicetwo img {
  border: 3px solid #fff;
}

.subnav-content {
  box-shadow: 0px 0px 15px #888888;
}

/* .navbar a:hover, .subnav:hover .subnavbtn {
  background-color: red;
} */
.subnav-content.Industries {
  display: none;
  position: absolute;
  right: 0;
  background-color: rgb(255, 255, 255);
  z-index: 1;
}

.subnav-content {
  display: none;
  position: absolute;
  right: 0;
  background-color: rgb(255, 255, 255);
  z-index: 1;
}

.subnav-content a {
  color: rgb(0, 0, 0);
  text-decoration: none;
}

.dropdown-item a {
  text-decoration: none;
  color: #1f589a;
  font-weight: 500;
}

.subnav-content a:hover {
  /* background-color: #1f58af; */
  /* color: white; */
  padding: 4px 9px;
  transition: 0.3s;
  border-radius: 5px;
}

.subnav:hover .subnav-content {
  display: block;
  transition: 1s;
  animation: mymove 0.4s;
}

.container {
  width: 1440px;
  margin: auto;
}

.logo_menu {
  position: relative;
  padding: 25px 0px 0px 0px !important;
}

.subserviceone ul {
  padding: 5px 0px 0px 15px !important;
  position: relative;
}

.d-flex {
  display: flex;
}

.justify-content-center {
  justify-content: center;
}

.align-items-center {
  align-items: center;
}

.justify-content-between {
  justify-content: space-between;
}

/* ------------ custome chage css start-------- */
.bannet_points {
  padding: 10px;
  display: flex;
  justify-content: center;
}

.bannerhome .bannet_points ul {
  list-style-type: disclosure-closed;
  font-size: 18px;
  text-align: left;
}

.bannerhome .bannet_points ul li {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.01em;
  margin-bottom: 6px;
}

/* ------------ custome chage css End-------- */
.bannerhome {
  height: 100vh;
  /* position: absolute; */
  width: 100%;
  left: 0px;
  top: 0;
  z-index: -1;
  display: flex;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

ul {
  list-style: none;
  padding: 0px !important;
  margin: 0px !important;
}

li.secsubmenus {
  padding: 18px 0px;
  font-size: 16px;
  color: #ffffff;
  font-weight: 500;
}

li.submenulist {
  padding: 10px;
  margin: 12px;
  position: relative;
}

li.submenulist a::before {
  content: "";
  background-color: #000000;
  width: 82%;
  height: 1px;
  position: absolute;
  transform: rotate(0deg);
  left: 12px;
  margin-top: 0px;
  top: 0px;
}

.subnavbtn {
  margin: 20px auto;
  padding: 0;
  width: auto;
  font-family: sans-serif;
  text-transform: uppercase;
  text-align: center;
  display: inline-block;
  transition-property: transform;
  transition: 0.25s ease;
}

.subnavbtn::after {
  content: '';
  border-top: 2px solid #1f589b;
  width: 77%;
  position: absolute;
  display: block;
  transform: rotateY(90deg);
  transition: transform 0.25s linear;
}

.subnavbtn:hover {
  transform: scale(1);
}

.subnavbtn:hover::after {
  transform: rotate(0deg);
}

.bannersec_conetct {
  color: #fff;
  text-shadow: 3px -7px 20px #000000;
  text-align: center;
}

.bannersec_conetct h1 {
  font-size: 95px;
  font-weight: 600;
}

.site-text-content h1 {
  font-size: 31px;
  font-weight: 450;
  line-height: 47px;
  color: #1f589b;
  margin-bottom: 30px;
}

button.btn.btn-outline-primary.sitebutton {
  color: #1f589b;
  border: 1px solid #1F589A;
  font-size: 20px;
  font-weight: 600;
}

button.btn.btn-outline-primary.sitebutton:hover {
  color: #fff;
  background-color: #1f589a;
}

button.btn.btn-outline-primary.sitebuttonrepet {
  color: #fff;
  border: 2px solid #1F589A;
  font-size: 20px;
  font-weight: 500;
  background-color: #1f589a;
}

button.btn.btn-outline-primary.sitebuttonrepet:hover {
  color: #1f589a;
  background-color: #1f589a00;
}

.img-with-text {
  padding: 100px 0px;
}

.industries_section h1,
.Services_section h1,
.clientslogo h1,
.blog-post h1 {
  text-align: center;
  font-size: 70px;
  font-weight: 600;
  color: #1f589a;
}

.logo_section h1 {
  font-size: 70px;
  font-weight: 600;
  color: #1f589a;
  padding-left: 70px;
}

.Services_section h3,
.industries_section h3,
.clientslogo h3 {
  text-align: center;
  color: #1f589a;
  font-size: 23px;
  font-weight: 400;
}

.serviceslist {
  color: #1f589a;
  text-align: center;
  padding: 90px 0px;
}

.serviceslist p {
  font-size: 18px;
  font-weight: 500;
}

.serviceslist h2 {
  font-size: 22px;
  padding-top: 55px;
}

.servivecontent {
  /* background-color: #E9EFF5; */
  border: 2px solid #1f589a;
  padding: 30px;
  border-radius: 18px;
}

.massgelines {
  padding-top: 10px;
}

div#massgelines {
  padding-top: 18px;
}

.thankyo {
  color: #fff;
  /*display: none;*/
}

.errorline {
  color: red;
  padding: 6px;
  background-color: #fff;
  /*display: none;*/
}

.serviceslist .col-lg-4 {
  position: relative;
}

/* .serviceslist .imgwithtext {
  border-radius: 30%;
  background-color: #ffffff;
  padding: 16px;
  border: 2px solid;
  position: absolute;
  bottom: 82%;
  left: 39%;
} */

/* -------- custome chage css start ---------- */
.serviceslist .imgwithtext {
  background-color: #fff;
  border: 2px solid;
  border-radius: 30%;
  top: -40px;
  left: 39%;
  padding: 16px;
  position: absolute;
}

/* -------- custome chage css end ---------- */

.secrow {
  padding-top: 100px;
}

.fastoneindustri {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
}

.thrdoneindustri {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}

.col.seconeindustri {
  text-align: center;
}

.sixshapliast.financial,
.sixshapliast.higher {
  margin-left: -158px;
}

.sixshapliast.manufacturing,
.sixshapliast.defence {
  margin-right: -158px;
}

.logo-contant-btn p {
  font-size: 21px;
  font-weight: 400;
  color: #1f589a;
}

.logo-contant-btn {
  background: #E9EFF5;
  clip-path: polygon(0 0, 100% 0, 62% 100%, 0% 100%);
  padding: 75px 160px 75px 70px;
  width: 100%;
}

.col-7.partner-logo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 30px;
}

.col-7.partner-logo .col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.row.industrieslist {
  padding-top: 25px;
}

.logo_section,
.industries_section {
  padding-bottom: 110px;
}

.logo-content {
  border-bottom: 2px solid #1f589a;
  border-top: 2px solid #1f589a;
}

.row.clintlogos {
  padding: 60px 0px;
}

.row.clintlogos.col {
  display: flex;
  align-items: center;
}

.industrihover:hover {
  width: fit-content;
  margin: auto;
  background: #e9eff5;
  clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
}

.sixshapliast .industrihover img {
  object-fit: cover;
  height: 175px;
}

.col.fastoneindustri {
  z-index: 1;
}

.col.seconeindustri,
.col.fastoneindustri,
.col.thrdoneindustri {
  text-align: center;
  row-gap: 18px;
  display: flex;
  flex-direction: column;
}

.serviceslist .col-lg-4:hover .imgwithtext {
  background: #E9EFF5;
  transition: 0.5s;
}

.servivecontent:hover {
  background: #E9EFF5;
  transition: 0.5s;
}

.card {
  margin: auto;
  /* box-shadow: 0px 0px 31px #00000024; */
  border-radius: 32px !important;
}

img.card-img-top {
  border-radius: 30px 30px 0px 0px;
  border-bottom: 2px solid #1f589a;
}

.card-body {
  background-color: #e9eff5;
  border-radius: 0px 0px 30px 30px;
}

h6.blogdate {
  text-align: center;
  background-color: #d5d5d5;
  width: 34%;
  padding: 8px;
  border-radius: 20px;
  color: #565656;
  font-size: 13px;
}

.card .btn.btn-outline-primary.sitebuttonrepet {
  margin: 10px 0px;
}

p.card-text,
.card-title.h5,
.blogdate {
  margin: 10PX 0PX;
}

.card-body {
  flex: 1 1 auto;
  padding: 1rem 2rem !important;
}

.cta-section {
  margin: 100px 0px;
}

.ctabackground {
  padding: 115px 0px;
  color: #fff;
  text-align: center;
  line-height: 80px;
  background-color: rgb(0 0 0 / 66%);
}

.ctabackground h1 {
  font-size: 45px;
}

/* --------- custome css change start -------- */
.ctabackground h3 {
  font-weight: 500;
}

/* --------- custome css change end -------- */

button.btn.btn-btn.btn-outline-primary.learn {
  font-size: 22px;
  font-weight: 500;
  padding: 10px 51px;
  color: rgb(255, 255, 255);
  border-color: #fff;
  background-color: rgba(255, 255, 255, 0);
}

button.btn.btn-btn.btn-outline-primary.learn:hover {
  font-size: 22px;
  font-weight: 500;
  padding: 10px 51px;
  color: #000;
  border-color: #fff;
  background-color: #fff;
}

.site-footer {
  background-color: #e9eff5;
  color: #1f589a;
  padding: 40px 0px 0px 0px;
}

.site-footer a {
  color: #1f589a;
  text-decoration: none;
}

.copyright_line ul {
  display: flex;
  background-color: #1f589a;
  color: #fff;
  justify-content: center;
  column-gap: 40px;
  padding: 15px 0px !important;
  margin: 30px 0px 0px 0px !important;
}

#button-addon2 {
  background-color: #1f589a;
  color: #fff;
}

button#button-addon2:hover {
  background-color: #0000;
  color: #1f589a;
  border-color: #1f589a;
}

.row.footerconter li {
  font-weight: 500;
  padding: 6px 0px;
}

.copyright_line a {
  color: #ffffff;
  text-decoration: none;
}

.copyright_line a:hover {
  color: #fff;
  text-decoration: none;
}

.react-multi-carousel-item {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
}

ul.react-multi-carousel-track {
  padding: 62px 0px !important;
}

.whats-button {
  padding: 15px 35px;
  color: #FFF !important;
  border-radius: 50px;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 1px;
  background: #1F589A;
  text-transform: uppercase;
  font-family: arial;
  text-decoration: none;
  position: fixed;
  bottom: 50%;
  right: -60px;
  transform: rotate(270deg);
}

.whats-button:before {
  content: "";
  border-radius: 50px;
  border: 5px solid #1F589A;
  transition: 0.3s;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  -webkit-animation: whats-border .6s infinite;
  animation: whats-border .6s infinite
}

.react-multiple-carousel__arrow--right {
  right: calc(0% + 1px) !important;
}

.react-multiple-carousel__arrow--left {
  left: calc(0% + 1px) !important;
}

.navbar-light .navbar-nav .nav-link {
  color: rgb(31 88 154) !important;
  font-size: 18px;
  font-weight: 500;
}

.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .show>.nav-link {
  color: rgb(31 88 154);
}

.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
  color: rgb(31 88 154);
}

div#basic-navbar-nav {
  background-color: #fff;
  padding: 5px 14px;
  margin-top: 8px;
}

.dropdown-item {
  padding: 5px 14px !important;
  font-weight: 600 !important;
  color: #1F589A !important;
}

.dropdown-menu {
  border: 0px solid rgba(0, 0, 0, .15) !important;
}

a.dropdown-item {
  position: relative;
}

a.dropdown-item:before {
  width: 22px;
  height: 2px;
  position: absolute;
  background-color: #1f589a;
  content: "";
  transform: rotate(90deg);
  left: -5px;
  top: 16px;
}

.mobhil_view .container {
  padding: 0px !important;
}

.site-text-content span h5 {
  margin: 12px 0px;
}

.homepage .img-with-text .col {
  padding: 0px 40px;
}

.aboutuspage .img-with-text .col {
  padding: 0px 30px;
}

.row.mobhilfooter {
  text-align: center;
}

.mobhilfooter .navbar-nav .dropdown-menu {
  position: static;
  text-align: center;
}

.mobhilfooter .me-auto {
  margin: auto !important;
}

.navbar-nav {
  width: 100%;
}

@-webkit-keyframes whats-border {
  100% {
    top: -12px;
    right: -12px;
    bottom: -12px;
    left: -12px;
    opacity: 0
  }
}

@keyframes whats-border {
  100% {
    top: -12px;
    right: -12px;
    bottom: -12px;
    left: -12px;
    opacity: 0
  }
}

@media(max-width:1440px) {
  .row {
    --bs-gutter-x: 0 !important;
    --bs-gutter-y: 0;
  }

  .col.seconeindustri,
  .col.fastoneindustri,
  .col.thrdoneindustri {
    row-gap: 7px;
  }

  .serviceslist .col-lg-4 {
    flex: 0 0 auto;
    width: 30%;
  }

  .serviceslist .row {
    display: flex;
    justify-content: center;
    column-gap: 20px;
  }
}

@media(max-width:1199.98px) {
  .servivecontent {
    height: 300px;
  }

  .serviceslist .imgwithtext {
    left: 34%;
  }

  .serviceslist .row {
    column-gap: 12px;
    display: flex;
    flex-wrap: nowrap;
  }

  .subnav-content {
    width: 70% !important;
  }

  .subnav-content.Industries {
    width: 100% !important;
  }

  .img-with-text .imgtextcont img {
    width: 100%;
  }

  .site-text-content h1 {
    font-size: 22px;
    line-height: 38px;
  }

  .img-with-text .site-text-content {
    padding-left: 25px;
  }

  .sixshapliast.financial,
  .sixshapliast.higher {
    margin-left: -100px;
  }

  .sixshapliast.manufacturing,
  .sixshapliast.defence {
    margin-right: -100px;
  }

  .subnav-content.aboutus {
    width: 30% !important;
  }

  .col-7.partner-logo .row {
    column-gap: 160px;
    row-gap: 50px;
  }

  .Our_Business h2 {
    font-size: 20px !important;
  }

  .servivecontent {
    /* background-color: #E9EFF5; */
    border: 2px solid #1f589a;
    padding: 20px;
    border-radius: 18px;
  }
}

@media(max-width:991.98px) {
  .serviceslist .col-lg-2 {
    display: none;
  }

  .sixshapliast.financial,
  .sixshapliast.higher {
    margin-left: -58px;
  }

  .sixshapliast.manufacturing,
  .sixshapliast.defence {
    margin-right: -58px;
  }

  .servivecontent {
    height: 350px;
    padding: 15px;
  }

  .serviceslist .imgwithtext {
    left: 25%;
  }

  .img-with-text {
    text-align: center;
  }

  .subnav-content.aboutus {
    width: 45% !important;
  }

  .subnav-content {
    width: 100% !important;
  }

  .img-with-text .row {
    display: contents;
  }

  .img-with-text .site-text-content {
    padding: 25px;
  }

  .logo_menu .site_logo img {
    width: 185px;
  }

  .logo-content .row {
    display: flex;
    flex-direction: column;
  }

  .col-5.partaner_content {
    width: 100%;
  }

  .col-7.partner-logo {
    width: 100%;
  }

  .logo-content .row {
    display: flex;
    flex-direction: row;
  }

  .col-7.partner-logo .row {
    column-gap: 0px;
    row-gap: 50px;
    padding: 22px;
  }

  .col-7.partner-logo .row {
    column-gap: 0px;
    row-gap: 50px;
  }

  .img-with-text {
    padding: 60px 0px;
  }

  .logo_section,
  .industries_section {
    padding-bottom: 55px;
  }

  .row.footerconter .col-2 {
    width: 50%;
    padding: 18px;
  }

  .row.footerconter .col-4 {
    width: 60%;
    text-align: center;
  }

  .row.footerconter {
    display: flex;
    justify-content: center;
  }

  .blog-post .card {
    width: 21rem !important;
  }

  .blog-post .col:nth-last-child(1) {
    margin-top: 15px;
  }

  .ctabackground h1 {
    font-size: 35px;
  }

  .cta-section {
    background-position: right;
  }

  .navbarmenu,
  .footerconter {
    display: none !important;
  }

  .mobhil_view,
  .mobhilfooter {
    display: block !important;
  }
}

@media(max-width:425.98px) {

  .serviceslist .col-lg-4 {
    width: 80%;
  }

  .serviceslist .row {
    column-gap: 12px;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    align-items: center;
  }

  .servivecontent {
    height: 215px;
  }

  .serviceslist .imgwithtext {
    left: 34%;
  }

  .serviceslist .col-lg-4 {
    margin-bottom: 70px;
  }

  .secrow {
    padding-top: 0;
  }

  .logo-contant-btn {
    clip-path: polygon(0 0, 100% 0, 62% 100%, 0% 100%);
  }

  .sixshapliast.manufacturing,
  .sixshapliast.defence {
    margin-right: 0px;
  }

  .sixshapliast.financial,
  .sixshapliast.higher {
    margin-left: 0px;
  }

  .row.industrieslist {
    display: flex;
    flex-direction: column;
  }

  .col.fastoneindustri,
  .col.thrdoneindustri {
    display: contents;
  }

  .col.seconeindustri,
  .col.fastoneindustri,
  .col.thrdoneindustri {
    row-gap: 0px;
  }

  .logo-contant-btn {
    background: #E9EFF5;
    clip-path: polygon(0 0, 100% 0, 62% 100%, 0% 100%);
    padding: 40px 108px 40px 70px;
    width: 100%;
  }

  .blog-post .col:nth-last-child(2) {
    margin-top: 15px;
  }
}

@keyframes mymove {
  0% {
    display: block;
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.navbar-light .navbar-toggler {
  color: rgb(31 88 154) !important;
  border-color: rgb(31 88 154) !important;
}

/* about us page */
/* .aboutuspage .imgwithtext {
  border-radius: 30px !important;
} */
.aboutuspage .site-text-content h5 {
  color: #1F589A;
}

.aboutus .bannerhome::before {
  position: absolute;
  content: "";
  width: 100%;
  left: 0;
  top: 0;
  height: 100%;
  background-color: #00000040;
  z-index: -1;
  opacity: 0.5;
}

.aboutus .bannerhome {
  position: relative;
  z-index: -1;
}

.aboutuspage .site-text-content h4 {
  color: #1f589a;
}

.col.abouttext {
  display: flex;
  align-items: center;
  justify-content: center;
}

.history-tl-container {
  font-family: "Roboto", sans-serif;
  margin: auto;
  display: block;
  position: relative;
  text-align: center;
}

.history-tl-container ul.tl {
  margin: 20px 0;
  padding: 0;
  display: inline-block;

}

.dekstop-timeline .history-tl-container ul.tl li {
  list-style: none;
  margin: auto;
  /* margin-left: 200px; */
  min-height: 50px;
  /* background: rgba(255,255,0,0.1); */
  border-left: 3px dashed #1F589A;
  padding: 10px 0 35px 78px;
  position: relative;
}

.history-tl-container ul.tl li:last-child {
  border-left: 0;
}

.dekstop-timeline .history-tl-container ul.tl li::before {
  position: absolute;
  left: -54px;
  top: -5px;
  content: "";
  border: 8px solid rgba(255, 255, 255, 0.74);
  border-radius: 500%;
  background: #1F589A;
  height: 110px;
  width: 110px;
  transition: all 500ms ease-in-out;
}

.dekstop-timeline .history-tl-container ul.tl li.twofif::before {
  content: "2013";
  color: #fff;
  display: flex;
  font-size: 27px;
  font-weight: 500;
  justify-content: center;
  align-items: center;
}

.dekstop-timeline .history-tl-container ul.tl li.twosex::before {
  content: "2022";
  color: #fff;
  display: flex;
  font-size: 27px;
  font-weight: 500;
  justify-content: center;
  align-items: center;
}

.dekstop-timeline .history-tl-container ul.tl li.twoseven::before {
  content: "2017";
  color: #fff;
  display: flex;
  font-size: 27px;
  font-weight: 500;
  justify-content: center;
  align-items: center;
}

.dekstop-timeline .history-tl-container ul.tl li.twoeight::before {
  content: "2018";
  color: #fff;
  display: flex;
  font-size: 27px;
  font-weight: 500;
  justify-content: center;
  align-items: center;
}

.dekstop-timeline .history-tl-container ul.tl li.twonine::before {
  content: "2019";
  color: #fff;
  display: flex;
  font-size: 27px;
  font-weight: 500;
  justify-content: center;
  align-items: center;
}

.dekstop-timeline .history-tl-container ul.tl li.twoten::before {
  content: "2020";
  color: #fff;
  display: flex;
  font-size: 27px;
  font-weight: 500;
  justify-content: center;
  align-items: center;
}

.dekstop-timeline .history-tl-container ul.tl li.twoelev::before {
  content: "2021";
  color: #fff;
  display: flex;
  font-size: 27px;
  font-weight: 500;
  justify-content: center;
  align-items: center;
}

.history-tl-container ul.tl li:hover::before {
  border-color: #1F589A;
  transition: all 1000ms ease-in-out;
}

.timelineimgwimg ul.tl li .item-detail {
  color: rgba(0, 0, 0, 0.5);
  font-size: 12px;
}

.timelineimgwimg ul.tl li .timestamp {
  color: #8D8D8D;
  position: absolute;
  width: 100px;
  left: -50%;
  text-align: right;
  font-size: 12px;
}

.history-tl-container .col {
  padding: 0px 25px;
  text-align: center;
}

.tl-item .d-flex {
  display: flex !important;
  flex-wrap: nowrap;
}

.history-tl-container .col:nth-child(3) {
  border-left: 2px solid #1f589a;
}

.history-tl-container .col:nth-child(1) {
  border-right: 2px solid #1f589a;
}

.timelineimgwimg {
  background-color: #e9eff5;
  padding: 60px 0px;
}

.advantage-section h1,
.team_section h1 {
  text-align: center;
  font-size: 70px;
  font-weight: 600;
  color: #1f589a;
}

.advantage-section h3 {
  text-align: center;
  color: #1f589a;
  font-size: 23px;
  font-weight: 400;
}

.advantagelist {
  display: flex;
  justify-content: center;
  position: relative;
  align-items: center;
  width: 75%;
  margin: auto;
  padding: 30px 0px;
}

.advantagelogo {
  background-color: #1f589a;
  border-radius: 50%;
  padding: 20px;
  position: absolute;
  left: -11%;
}

.advantagecontent {
  border: 2px solid #1f589a;
  padding: 30px 30px 30px 75px;
  color: #1f589a;
  box-shadow: 1px 1px 25px #c5c5c5;
}

.advantage-section .row {
  margin: 30px 0px;
}

.advantage-section {
  margin: 40px 0px;
}

img.teamimgdp {
  border-radius: 25px;
}

.teaminfo {
  background-color: #1f589a;
  color: #fff;
  text-align: center;
  padding: 15px;
  width: 85%;
  border-radius: 10px;
  margin-top: -40px;
  box-shadow: 1px 1px 10px #4f4f4f;
}

.teambox {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.advantagecontent:hover {
  background-color: #1f589a;
  color: #fff;
  transition: 0.5s;
}

.timelineimgwimg.mobhil_timeline {
  display: none;
}

@media(max-width:1199.98px) {
  .dekstop-timeline .history-tl-container ul.tl li {
    list-style: none;
    margin: auto;
    margin-left: 110px;
    min-height: 50px;
    border-left: 3px dashed #1F589A;
    padding: 10px 0 70px 78px;
    position: relative;
  }

  li.tl-item.twoseven .item-detail:hover {
    width: 220px;
  }

  img.teamimgdp {
    width: 350px !important;
  }

  .timelineimgwimg.mobhil_timeline .row {
    display: flex;
  }

  .timelineimgwimg.mobhil_timeline .col {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px;
  }
}

@media(max-width:991.98px) {
  .mobhil_timeline .history-tl-container ul.tl li {
    list-style: none;
    margin: auto;
    margin-left: 0px;
    /* min-height: 50px; */
    /* background: rgba(255,255,0,0.1); */
    border-left: 0px dashed #1F589A;
    padding: 0px 0 54px -1px;
    position: relative;
  }

  h1.round_section {
    background-color: #1f589a;
    padding: 10px;
    color: #fff;
    margin: 0;
  }

  .timelineimgwimg.mobhil_timeline {
    display: flex;
  }

  .advantagelist {
    display: flex;
    justify-content: center;
    position: relative;
    align-items: center;
    margin: auto;
    padding: 30px 0px;
  }

  .advantage-section .row {
    display: block;
  }

  .timelineimgwimg.dekstop-timeline {
    display: none;
  }

  .timelineimgwimg.mobhil_timeline {
    display: block !important;
  }
}

@media(max-width:425.98px) {

  /* ------- custome change css start --------- */
  .servivecontent {
    height: auto;
  }

  .serviceslist .imgwithtext {
    bottom: 82% !important;
  }

  /* ------- custome css change start ------------ */
  .bannerhome {
    height: auto !important;
    padding: 90px 10px;
  }

  /* ------- custome css change end ------------ */

  /* ------- custome change css End --------- */
  .timelineimgwimg.mobhil_timeline .row {
    display: block;
  }

  .history-tl-container .col:nth-child(3) {
    border-left: 0px solid #1f589a;
  }

  .history-tl-container .col:nth-child(1) {
    border-right: 0px solid #1f589a;
  }

  .history-tl-container .col:nth-child(2) {
    border-bottom: 2px solid #1f589a;
  }

  .history-tl-container .col:nth-child(1) {
    border-bottom: 2px solid #1f589a;
  }

  .timelineimgwimg.mobhil_timeline .col {
    padding: 15px;
  }

  .advantagelogo {
    background-color: #1f589a;
    border-radius: 50%;
    padding: 20px;
    position: absolute;
    left: -17%;
  }
}

/* ---------- custome change css start --------- */
@media(max-width:361.98px) {

  .industries_section h1,
  .Services_section h1,
  .clientslogo h1,
  .blog-post h1 {
    font-size: 60px;
  }

  .logo_section h1 {
    font-size: 60px;
    padding: 10px;
  }

  .serviceslist .imgwithtext {
    bottom: 86% !important;
  }
}

/* ---------- custome change css end--------- */
/* Investors page */
.investors .bannersec_conetct {
  color: #1f589a;
  text-shadow: 1px 1px 15px #00000075;
  text-align: center;
  width: 50%;
  margin: auto;
  background-color: #e9eff5bd;
  padding: 20px;
  border-radius: 30px;
}

.investors .bannersec_conetct {
  color: #1f589a;
  text-shadow: 0px 0px 0px #00000075;
  text-align: center;
}

.stockprice.d-flex.justify-content-center {
  gap: 50px;
}

.investors .bannersec_conetct h3 {
  padding-bottom: 10px;
}

.investors .img-with-text {
  padding: 50px 0px;
}

h1.featured_hadding {
  text-align: center;
  font-size: 60px;
  font-weight: 600;
  color: #1f589a;
}

h3.featurde_sulog {
  text-align: center;
  color: #1f589a;
  font-size: 23px;
  font-weight: 400;
}

.Featured_Press_Releases .imgtextcont img {
  border-radius: 20px;
  box-shadow: 1px 1px 30px #1f589a;
}

.Featured_Press_Releases button.btn.btn-btn.btn-outline-primary.sitebuttonrepet {
  margin-top: 20px;
  padding: 8px 30px;
}

.investors .site-text-content h5 {
  color: #1f589a;
  font-weight: 700;
}

.prin_progess {
  background-color: #1f589c82;
  height: 250px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  border: 4px dashed #1f589a;
  color: #fff;
}

.prin_progess h1 {
  font-size: 85px;
}

.progresslevel {
  background-color: #e9eff5;
  padding: 60px 0px;
  margin: 40px 0px;
}

h5.progboxname {
  text-align: center;
  font-size: 24px;
  color: #1f589a;
  margin: 28px 0px;
}

.Our_Business {
  background: #E9EFF5;
  clip-path: polygon(0% 30%, 100% 0, 100% 100%, 0% 100%);
  padding: 75px 70px 75px 70px;
  width: 100%;
}

.Our_Business .featured_hadding {
  margin: 105px 0px 30px 0px;
}

.Our_Business .servivecontent {
  text-align: center;
}

.Our_Business .imgwithtext {
  background-color: #e9eff5;
  box-shadow: 0px 0px 15px #1f589a;
  border-radius: 20%;
  padding: 9px;
}

.Our_Business h2 {
  color: #1f589a;
  padding: 10px 0px;
  font-size: 25px;
}

.Investor_Services .advantagelogo {
  background-color: #1f589a;
  border-radius: 50%;
  padding: 20px;
  position: initial;
  margin-right: -11%;
  z-index: 9;
}

.Investor_Services .advantagelist {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  /* margin: auto; */
  padding: 30px 0px;
}

.Investor_Services {
  padding: 50px 0px;
}

.cta_section {
  color: #fff;
  padding: 50px 0px;
}

@media(max-width:1440.98px) {
  .Investor_Services .advantagelogo {
    padding: 10px;
  }
}

@media(max-width:1199.98px) {

  /* -----custome css chage Start ------- */
  .servivecontent {
    height: auto;
  }

  /* -----custome css chage End ------- */
  .Investor_Services .advantagelogo {
    margin-right: -8%;
  }
}

@media(max-width:991.98px) {
  .Investor_Services .advantagelogo {
    margin-right: -8%;
  }

  .investors .bannersec_conetct {
    width: 90%;
  }

  .progresslevel .col-3 {
    width: 50%;
  }

  .progresslevel .col-6 {
    width: 100%;
  }

  .responroted .abouttext {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .img-with-text.responroted .row {
    display: flex;
    flex-direction: column-reverse;
  }

  .Our_Business .featured_hadding {
    margin: 30px 0px 30px 0px;
  }

  .Our_Business {
    clip-path: polygon(0% 0%, 100% 0, 100% 100%, 0% 100%);
    padding: 35px 50px 35px 50px;
  }
}

@media(max-width:768.98px) {

  /*----------------- custome change css start --------------- */
  .serviceslist .imgwithtext {
    bottom: 90%;
  }

  /*----------------- custome change css end --------------- */
  .Investor_Services .advantagelogo {
    position: absolute;
  }

  .bannersec_conetct h1 {
    font-size: 60px;
    font-weight: 600;
  }

  .progresslevel .col-3 {
    width: 100%;
  }

  .progresslevel .col-6 {
    width: 100%;
  }

  .peogresschartsection {
    width: 100%;
    margin: auto;
  }

  .Our_Business {
    padding: 35px 10px 35px 10px;
  }

  .Our_Business .row {
    gap: 15px;
  }

  .Investor_Services .advantagelogo {
    position: absolute;
    top: -80px;
    left: 2%;
  }

  .Investor_Services .advantagelist {
    margin-top: 90px;
  }

  .advantagecontent {
    padding: 30px 30px 30px 75px;
  }
}

/*Partners testiomoniyal pars */
.partners .cta-section {
  margin: 0px 0px;
}

.peogresschartsection {
  width: 50%;
  margin: auto;
}

div#partner968 .bannersec_conetct {
  color: #1f589a;
  text-shadow: 0px 0px 0px #000000;
}

.major_partnerships .servivecontent {
  height: 400px;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.major_partnerships {
  padding: 25px 0px;
}

.patnerboxs {
  text-align: center;
}

.patnerboxs .sitebuttonrepet {
  margin: 15px 0px;
}

.patnerboxs h6 {
  height: 100px;
}

.Other_Partnerships {
  background-color: #e9eff5;
  padding: 40px 0px;
}

.patnerwinfo .site-text-content h1 {
  font-size: 44px;
  font-weight: 500;
  line-height: 47px;
  color: #1f589b;
  margin-bottom: 30px;
}

.patnerwinfo .site-text-content h6 {
  font-size: 18px;
  padding-bottom: 10px;
}

.testimonials {
  text-align: center;
  margin-top: 70px;
}

h1.testimon_head {
  font-size: 45px;
  color: #1F589C;
  padding-bottom: 50px;
}

.testimonal_content {
  background-color: #d2deeb;
  padding: 40px 25px;
  border-radius: 15px;
}

.quote_ic i {
  font-size: 80px;
  color: #1F589C;
}

p.info_cont {
  max-width: 80%;
  font-weight: 500;
}

h5.dave_c {
  color: #1F589C;
}

p.officer {
  font-size: 19px;
  font-weight: 400;
  color: #1F589C;
}

.sec_fourth {
  position: relative;
}

img.testimon_image {
  position: absolute;
  top: 17%;
  right: 5%;
  background-color: transparent;
  border: none;
}

.chief_officer {
  padding-top: 10px;
  line-height: 16px;
}

@media(max-width:1440.98px) {
  .major_partnerships .servivecontent {
    height: 350px;
  }

  .serviceslist {
    padding: 40px 0px;
  }

  .partners .secrow {
    padding-top: 20px;
  }
}

@media(max-width:1199.98px) {
  .major_partnerships .servivecontent {
    height: 400px;
  }
}

@media(max-width:991.98px) {
  .major_partnerships .serviceslist .row {
    display: flex;
    flex-wrap: wrap;
    row-gap: 12px;
  }

  .major_partnerships .servivecontent {
    height: 360px;
  }

  img.testimon_image {
    top: -13%;
    right: 4%;
  }
}

/* Contect us urf get in touch */
.contect_info {
  background-color: #e9eff5;
  margin: 60px 0px;
  padding: 40px;
  border-radius: 32px;
}

.col.contect_info_col {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.icon_w_text.d-flex.align-items-center {
  margin: 10px 0px;
}

.text_s {
  margin: 0px 25px;
  color: #1f589a;
}

h1.contect_us_hadding {
  font-size: 55px;
  color: #1f589a;
  font-weight: 700;
}

.contectform {
  background-color: #1f589a;
  padding: 55px;
  border-radius: 0px 25px 25px 0px;
}

.contectform label.form-label {
  color: #fff;
  font-size: 21px;
}

.contectform .form-control {
  padding: 10px 15px;
}

.contectform button.contectform_sumbitbtn.btn.btn-light {
  font-size: 20px;
  padding: 7px 42px;
  margin-top: 10px;
  color: #1f589a;
  font-weight: 500;
  border: 2px solid;
}

.contectform button.contectform_sumbitbtn.btn.btn-light:hover {
  font-size: 20px;
  padding: 7px 42px;
  color: #ffffff;
  background-color: rgba(255, 255, 255, 0);
  font-weight: 500;
  border: 2px solid;
}

.icon_w_text:hover .text_s {
  background-color: #1f589a;
  color: #fff;
  padding: 10px 10px;
  transition: 0.5s;
  border-radius: 10px;
}

.icon_text.d-flex.align-items-center {
  justify-content: center;
}

.icon_text h5 {
  margin-left: 18px;
  color: #1f589a;
}

.email_number {
  margin-top: 10px;
}

.icon_text {
  display: flex;
  justify-content: center;
}

.contectboxs {
  text-align: center;
}

.phoneicons {
  filter: brightness(0) invert(1);
}

.contectboxs .col-4:hover .phoneicons {
  filter: inherit;
  transition: 0.5s;
}

.contectboxs .col-4 {
  margin: auto;
  padding: 30px;
  margin-top: 60px;
  border-radius: 10px;
  border: 3px solid;
  background-color: #1f589a;
  color: #ffffff;
}

.contectboxs .col-4:hover {
  margin: auto;
  margin-top: 60px;
  border-radius: 10px;
  border: 3px solid;
  background-color: #1f589a00;
  color: #1f589a;
  transition: 0.5s;
}

@media(max-width:1199.98px) {
  .contect_info .row {
    display: contents;
  }

  .email_number .row {
    display: flex;
    margin-bottom: 35px;
  }
}

@media(max-width:767.98px) {

  .contect_info {
    padding: 15px;
  }

  .contectform {
    background-color: #1f589a;
    padding: 23px;
    border-radius: 0px 25px 25px 0px;
  }

  .email_number .row {
    display: flex;
    margin-bottom: 35px;
    flex-direction: column;
    gap: 15px;
  }

  .contectboxs .col-4 {
    width: 100% !important;
  }
}

a.fixbuttons {
  background-color: #1f589a;
  color: #fff;
  text-decoration: none;
  font-size: 20px;
  margin: 0px 0px;
  border-radius: 0px 50px 50px 0px;
  padding: 10px 15px;
  border: 2px solid #1F589A;
}

a.fixbuttons:hover {
  background-color: #e9eff5;
  color: #1F589A;
  text-decoration: none;
  font-size: 20px;
  margin: 0px 0px;
  border-radius: 0px 50px 50px 0px;
  padding: 10px 15px;

}

.click_down_menu {
  color: #FFF !important;
  text-transform: uppercase;
  position: fixed;
  bottom: 100px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  z-index: 9;
}


/* Advisory-Consulting page */
.accordion .accordion-item {
  margin-bottom: 18px;
}

button.accordion-button.collapsed {
  font-size: 20px;
  font-weight: 500;
  color: #1f589a;
  background-color: #e9eff5;
}

.accordion-item {
  background-color: #e9eff5 !important;
  border: 2px solid rgb(31 88 154) !important;
}

.accordion-button:not(.collapsed) {
  color: #ffffff !important;
  background-color: #1f589a !important;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, .125);
  font-size: 20px;
  font-weight: 500;
}

.accordion-button:not(.collapsed)::after {
  filter: brightness(0) invert(1) !important;
}

.accordion-button:focus {
  border-color: #1f589a !important;
  box-shadow: 0 0 0 0.25rem rgb(31 88 154) !important;
}

.acordyantext {
  font-size: 18px;
  color: #1f589a;
}

b.acordyantext {
  line-height: 60px;
}

.ascodiyan_tubing {
  margin: 40px 0px !important;
}

.benefits_section {
  background-color: #e9eff5;
  color: #1f589a;
}

h1.benefits_hadding {
  font-weight: 600;
  font-size: 70px;
  right: 70px;
  bottom: 12px;
}

.benefits_section {
  background-color: #e9eff5;
  color: #1f589a;
  padding: 72px 0px;
  margin: 100px 0px;
}

.col-3.hadding_benefit {
  display: flex;
  align-items: center;
}

.col-3.nenefitbox {
  padding: 22px;
  border-left: 2px solid #1f589a;

}

.img-with-text.client_story {
  padding: 70px 0px;
}

.img-with-text.client_story h6 {
  margin-bottom: 30px;
}

.img-with-text.client_story .imgwithtext {
  border-radius: 8px 120px;
}

.dataintegration .bannersec_conetct h1 {
  font-size: 95px;
  font-weight: 600;
  background-color: #1f589ac7;
  width: 65%;
  margin: auto;
}

.dataintegration .ctabackground {
  padding: 60px 0px;
  color: #fff;
  text-align: center;
  line-height: 80px;
  background-color: rgb(0 0 0 / 66%);
}

.dataintegration .cta-section {
  margin: 0px 0px;
}

.site-text-content h6 {
  font-size: 18px;
}

.managedsevice .cta-section {
  margin: 0px 0px;
}

.managedsevice .ctabackground {
  padding: 80px 0px;
}

/* .service_highlights h3.featurde_sulog, .service_highlights h1.featured_hadding {
  text-align: left;
} */
.crosalbox {
  background-color: #1f589a;
  color: #fff;
  padding: 35px;
  border: 7px solid;
  height: 240px;
}

.service_highlights ul.react-multi-carousel-track {
  padding: 0px 0px !important;
}

div#benefits28 .row {
  display: flex;
  align-items: center;
  padding: 14px 0px;
}

.clints_logo {
  margin: 90px 0px;
}

.clints_logo img {
  margin: auto !important;
  display: flex;
}

.wlthpage span {
  text-align: justify;
}

.col.imgtextcont {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media(max-width:1440.98px) {
  a.fixbuttons {
    background-color: #1f589a;
    color: #fff;
    text-decoration: none;
    font-size: 18px;
    margin: 0px 0px;
    border-radius: 0px 50px 50px 0px;
    padding: 6px 15px;
    border: 2px solid #1F589A;
  }

  a.fixbuttons:hover {
    background-color: #e9eff5;
    color: #1F589A;
    text-decoration: none;
    font-size: 18px;
    margin: 0px 0px;
    border-radius: 0px 50px 50px 0px;
    padding: 6px 15px;
  }
}

@media(max-width:1199.98px) {
  div#benefits28 .row {
    display: flex;
    align-items: center;
    padding: 0px 0px;
    flex-direction: column;
  }

  div#benefits28 .col-3 {
    width: 45%;
  }
}

@media(max-width:991.98px) {
  .dataintegration .bannersec_conetct h1 {
    font-size: 70px;
  }

  div#service28 .row {
    display: flex;
    flex-direction: column;
  }

  div#service28 .col-4,
  .col-8 {
    width: 100% !important;
  }

  div#benefits28 .row {
    display: flex;
    flex-direction: column;
    gap: 35px;
  }
}

@media(max-width:767.98px) {
  .dataintegration .bannersec_conetct h1 {
    font-size: 48px;
  }

  .bannerhome {
    height: 70vh;
  }

  a.fixbuttons {
    font-size: 14px;
    padding: 5px 9px;
  }

  a.fixbuttons:hover {
    font-size: 14px;
    padding: 5px 9px;
  }

  .click_down_menu {
    bottom: 22px;
  }

  .ascodiyan_tubing .col-8 {
    flex: 0 0 auto;
    width: 90%;
  }

  div#benefits28 .col-3 {
    width: 80%;
  }
}

.subservicetwo .secsubmenus a {
  font-size: 16px;
  color: #f0f0f0 !important;
  text-align: center;
  text-decoration: none;
  font-weight: 600;
}

.blog_scerachbar {
  background: #1f589a;
  padding: 48px 50px;
  margin: 40px 0px;
  border-radius: 25px;
}

.blog_scerachbar .btn.btn-outline-primary.sitebuttonrepet {
  color: #fff;
  border: 2px solid #1F589A;
  font-size: 20px;
  font-weight: 500;
  background-color: #1f589a;
  padding: 8px 50px;
}

.blogs_liasts .row {
  padding-bottom: 74px;
}

.pagination {
  justify-content: center;
}

.page-link {
  color: #1f589a !important;
  background-color: #e9eff5 !important;
  border: 1px solid #1f589a !important;
}

.page-link:hover {
  color: #ffffff !important;
  background-color: #1f589a !important;
  border-color: #1f589a !important;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff !important;
  background-color: #1f589a !important;
  border-color: #1F589A !important;
}

.clintstory .card-body,
.clintstory img.card-img-top {
  border-radius: 0px 0px 0px 0px;
}

.clintstory .bannersec_conetct {
  color: #1f589a;
  text-shadow: 0px 0px 2px #000000;
  text-align: center;
}

.clintstory .blogs_liasts .card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgb(31 88 154);
  border-radius: .25rem !important;
}

button.searchblog.btn.btn-primary {
  padding: 7px 55px;
  font-size: 21px;
  background-color: #ffffff;
  color: #212529;
  border: 2px solid #1f589a;
}

button.searchblog.btn.btn-primary:hover {
  background-color: #1f589a;
  color: #ffffff;
  border: 2px solid #ffffff;
  transition: 0.5s;
}

/* funlly responsive */

@media(max-width:1199.98px) {
  .blog-post .col:nth-last-child(1) {
    margin-top: 15px;
  }

  .blog-post .col:nth-last-child(2) {
    margin-top: 15px;
  }

  .blog-post .col:nth-last-child(3) {
    margin-top: 15px;
  }
}

@media(max-width:991.98px) {
  .img-with-text.client_story {
    display: flex;
    flex-direction: column-reverse;
  }
}